<template>
    <div class="w-100 p-2" v-loading="awsInfo.isLoading">
        <div class="w-100">
            <div class="d-md-flex align-items-center">
                <div class="flex-fill mb-2 mb-md-0">
                    <h4 class="mt-0 mb-0">
                        <strong>
                            Total spent <span class="text-primary">${{totalUsd}}</span>
                        </strong>
                    </h4>
                </div>
                <div class="mr-md-3 mb-2 mb-md-0">
                    <el-checkbox class="mr-3 mb-0" v-model="awsInfo.groupByRegion" label="Group by region"
                                 border size="small" @change="getCostExplorerMetrics"></el-checkbox>

                    <el-radio-group v-model="awsInfo.requestParams.granularity" size="small"
                                    @change="setGranularity">
                        <el-radio-button label="daily" class="m-0">
                            <strong>Daily</strong>
                        </el-radio-button>
                        <el-radio-button label="monthly" class="m-0">
                            <strong>Monthly</strong>
                        </el-radio-button>
                    </el-radio-group>
                </div>
                <div class="mr-md-3 mb-2 mb-md-0">
                    <el-date-picker v-model="awsInfo.requestParams.dateRange"
                                    size="small"
                                    :format="$elDatePickerFormat" type="daterange"
                                    @change="debounceFnc"
                                    value-format="yyyy-MM-dd"
                                    range-separator="To"
                                    start-placeholder="Start date"
                                    end-placeholder="End date">
                    </el-date-picker>
                </div>
                <div class="mb-3 mb-md-0">
                    <button class="btn btn-sm btn-primary" @click="getCostExplorerMetrics">
                        <i class="fas fa-sync-alt mr-2"></i> Refresh
                    </button>
                    <button class="btn btn-sm btn-primary ml-1" @click="reset">
                        Reset
                    </button>
                    <button class="btn btn-sm btn-primary ml-1" @click="thisMonth">
                        This month
                    </button>
                    <button class="btn btn-sm btn-primary ml-1" @click="lastMonth">
                        Last month
                    </button>
                </div>
            </div>
            <div class="mt-3">
                <highcharts :options="awsInfo.options"></highcharts>
            </div>
            <div v-if="(awsInfo.requestParams.groupBy !== null && awsInfo.requestParams.groupBy.length !== 0)">
                <div class="d-flex flex-column flex-md-row justify-content-center" style="margin-left: -0.5rem; margin-right: -0.5rem;">
                    <div class="m-2 flex-fill" :key="regionItem.code"
                         v-for="(regionItem, indexRegion) in awsRegionName.sort((a, b) => { return b.totalUsdRegion - a.totalUsdRegion; } )">

                        <div class="small-box mb-0 bg-info" :indexItem="indexRegion">
                            <div class="inner">
                                <h5 class="mb-0"><strong>${{regionItem.totalUsdRegion}}</strong></h5>
                                <span class="mb-0">{{regionItem.name}}</span>
                            </div>
                            <div class="icon">
                                <i class="fas fa-map-marker-alt" style="font-size: 2rem !important;"></i>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import awsRequestApi from '@/api/common/awsRequestApi';
    import { Chart } from 'highcharts-vue';
    import Highcharts from 'highcharts'
    import { areaSplineChart, colorSun, colorSat, colorNormal } from "@/constant/dashboardChartOptions";
    import moment from "moment";
    import { debounce } from 'vue-debounce';
    var momentDurationFormatSetup = require("moment-duration-format");
    momentDurationFormatSetup(moment);

    const awsRegionNameMap = {
        "ap-southeast-1": "Singapore",
        "ap-southeast-2": "Sydney",
        "ap-northeast-2": "Seoul",
        "ap-south-1": "Mumbai",
        "sa-east-1": "São Paulo",
        "us-east-1": "N.Virginia",
        "us-east-2": "Ohio",
        "us-west-1": "N.California",
        "eu-central-1": "Frankfurt",
        "eu-west-1": "Ireland",
    };

    export default {
        components: {
            highcharts: Chart
        },
        props: {
            titleName: {
                type: String,
                default: "AWS Cost Management"
            },
            params: {
                type: Object,
                default() {
                    return {
                        groupBy: [{ key: "REGION", type: "DIMENSION" }],
                        filterService: ["Amazon Elastic Compute Cloud - Compute", "Amazon Elastic Load Balancing", "EC2 - Other"],
                        metrics: ["BlendedCost"],
                    };
                },
            },
        },
        data() {
            return {
                debounceFnc: debounce(() => {

                }, 300),
                awsInfo: {
                    isLoading: false,
                    groupByRegion: false,
                    requestParams: {
                        granularity: "daily",
                        dateRange: []
                    },
                    options: Object.assign(JSON.parse(JSON.stringify(areaSplineChart)), {
                        chart: {
                            height: 550,
                            type: 'areaspline'
                        },
                        yAxis: [{ // Primary yAxis
                            labels: {
                                format: '${value}',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            },
                            title: {
                                text: '',
                                style: {
                                    color: Highcharts.getOptions().colors[1],
                                    "font-size": 15,
                                }
                            }
                        }],
                        tooltip: {
                            shared: true
                        },
                        plotOptions: {
                            areaspline: {
                                dataLabels: {
                                    enabled: true
                                },
                                fillOpacity: 0.5
                            }
                        },
                    }),
                    tableData: [],
                },
            };
        },
        computed: {
            awsRegionName() {
                return Object.keys(awsRegionNameMap).map(regionCode => {
                    let totalUsdRegion = 0;
                    if (this.awsInfo.requestParams.groupBy !== null && this.awsInfo.requestParams.groupBy.length !== 0) {
                        totalUsdRegion = this.awsInfo.tableData.reduce((total, element) => {
                            if (element.metricsCosts) {
                                let valueForRegion = element.metricsCosts.find(gr => gr.region === regionCode);
                                let amountForRegion = 0;
                                if (valueForRegion) {
                                    amountForRegion = valueForRegion.value.BlendedCost.amount;
                                }
                                total = total + parseFloat(amountForRegion);
                            }
                            return (Math.round((parseFloat(total) + Number.EPSILON) * 100) / 100);
                        }, 0);
                    }
                    return { code: regionCode, name: awsRegionNameMap[regionCode], totalUsdRegion };
                });
            },
            totalUsd() {
                return this.awsInfo.tableData.reduce((total, element) => {
                    if (this.awsInfo.requestParams.groupBy === null || this.awsInfo.requestParams.groupBy.length === 0) {
                        return total + (element.metricsCost != null ? (Math.round((parseFloat(element.metricsCost.amount) + Number.EPSILON) * 100) / 100) : 0);
                    }
                    else {
                        Object.keys(awsRegionNameMap).forEach(region => {
                            if (element.metricsCosts) {
                                let valueForRegion = element.metricsCosts.find(gr => gr.region === region);
                                let amountForRegion = 0;
                                if (valueForRegion) {
                                    amountForRegion = valueForRegion.value.BlendedCost.amount;
                                }
                                total = total + parseFloat(amountForRegion);
                            }
                        });
                        return (Math.round((parseFloat(total) + Number.EPSILON) * 100) / 100);
                    }
                }, 0);
            }
        },
        created() {
            this.awsInfo.requestParams.dateRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
            this.getCostExplorerMetrics();
            this.debounceFnc = debounce(() => {
                this.getCostExplorerMetrics();
            }, 300);
        },
        methods: {
            getCostExplorerMetrics() {
                this.awsInfo.isLoading = true;
                this.awsInfo.requestParams = { ...this.awsInfo.requestParams, ...this.params };
                if (this.awsInfo.groupByRegion) {
                    this.awsInfo.requestParams.groupBy = [{ key: "REGION", type: "DIMENSION" }];
                }
                else this.awsInfo.requestParams.groupBy = null;

                awsRequestApi.getCostExplorerMetrics(this.awsInfo.requestParams).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        let dateFormat = 'DD-MM-YYYY';
                        if (this.awsInfo.requestParams.granularity === 'monthly') dateFormat = 'MM-YYYY';
                        this.awsInfo.tableData = response.data.data;
                        this.awsInfo.options.xAxis.categories = [];
                        this.awsInfo.options.xAxis.categories = response.data.data.map(x => moment(x.dateStamp).format(dateFormat));

                        if (this.awsInfo.requestParams.groupBy === null || this.awsInfo.requestParams.groupBy.length === 0) {
                            this.awsInfo.options.legend = { enabled: false };
                            this.awsInfo.options.series = [{
                                name: "",
                                type: 'column',
                                dataLabels: {
                                    enabled: true,
                                    format: '{y}',
                                    inside: false,
                                    color: '#000'
                                },
                                tooltip: {
                                    valuePrefix: '$'
                                },
                                color: {
                                    linearGradient: {
                                        x1: 0,
                                        x2: 0,
                                        y1: 0,
                                        y2: 1
                                    },
                                    stops: [
                                        [0, '#91c0de'],
                                        [1, '#4897C9']
                                    ]
                                },
                                data: response.data.data.map(x => {
                                    return {
                                        y: x.metricsCost != null ? (Math.round((parseFloat(x.metricsCost.amount) + Number.EPSILON) * 100) / 100) : 0,
                                        marker: {
                                            fillColor: (moment.utc(x.dateStamp).day() === 0) ? colorSun : ((moment.utc(x.dateStamp).day() === 6) ? colorSat : colorNormal)
                                        }
                                    }
                                })
                            }];
                        }
                        else {
                            const regionListSeries = response.data.data.reduce(
                                (regionList, regionValue) => {
                                    if (regionValue.metricsCosts != null) {
                                        regionValue.metricsCosts.forEach((element) => {
                                            if (!regionList.includes(element.region)) {
                                                regionList.push(element.region);
                                            }
                                        });
                                    }
                                    return regionList;
                                },
                                []
                            );
                            this.awsInfo.options.legend = { enabled: true };
                            this.awsInfo.options.plotOptions.column = {
                                stacking: 'normal',
                                dataLabels: {
                                    enabled: true,
                                    format: '{y}',
                                }
                            };
                            this.awsInfo.options.series = regionListSeries.map(region => {
                                return {
                                    name: `${awsRegionNameMap[region]}`,
                                    type: 'column',
                                    dataLabels: {
                                        enabled: true,
                                        format: '{y}',
                                    },
                                    tooltip: {
                                        valuePrefix: '$'
                                    },
                                    data: response.data.data.map(x => {
                                        if (x.metricsCosts) {
                                            let valueForRegion = x.metricsCosts.find(gr => gr.region === region);
                                            let amountForRegion = 0;
                                            if (valueForRegion) {
                                                amountForRegion = valueForRegion.value.BlendedCost.amount;
                                            }
                                            return {
                                                y: valueForRegion ? (Math.round((parseFloat(amountForRegion) + Number.EPSILON) * 100) / 100) :  null,
                                                marker: {
                                                    fillColor: (moment.utc(x.dateStamp).day() === 0) ? colorSun : ((moment.utc(x.dateStamp).day() === 6) ? colorSat : colorNormal)
                                                }
                                            };
                                        }
                                        else {
                                            return {
                                                y: null,
                                                marker: {
                                                    fillColor: (moment.utc(x.dateStamp).day() === 0) ? colorSun : ((moment.utc(x.dateStamp).day() === 6) ? colorSat : colorNormal)
                                                }
                                            };
                                        }
                                    })
                                };
                            });
                        }

                        this.awsInfo.isLoading = false;
                    }
                    else {
                        this.awsInfo.isLoading = false;
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    this.awsInfo.isLoading = false;
                    console.error(error);
                });
            },
            setGranularity() {
                if (this.awsInfo.requestParams.granularity === "daily") {
                    this.awsInfo.requestParams.dateRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                    this.debounceFnc();
                }
                else {
                    this.awsInfo.requestParams.dateRange = [moment('2022-06-01').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                    this.debounceFnc();
                }
            },
            reset() {
                this.awsInfo.requestParams.dateRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                this.awsInfo.requestParams.granularity = "daily";
                this.getCostExplorerMetrics();
            },
            thisMonth() {
                this.awsInfo.requestParams.dateRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                this.awsInfo.requestParams.granularity = "daily";
                this.getCostExplorerMetrics();
            },
            lastMonth() {
                this.awsInfo.requestParams.dateRange = [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')];
                this.awsInfo.requestParams.granularity = "daily";
                this.getCostExplorerMetrics();
            },
        }
    }
</script>